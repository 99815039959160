import gql from 'graphql-tag'

export const FileFields = gql`
    fragment FileFields on File {
        _id
        path
        name
        createdAt
        updatedAt
    }
`

export const PermissionFields = gql`
    fragment PermissionFields on Permission {
        _id
        code
        createdAt
        updatedAt
    }
`

export const RoleFields = gql`
    fragment RoleFields on Role {
        _id
        code
        description
        permissions {
            ...PermissionFields
        }
        createdAt
        updatedAt
    }
    ${PermissionFields}
`

export const RoleShortenedFields = gql`
    fragment RoleShortenedFields on Role {
        _id
        code
    }
`

export const UserFields = gql`
    fragment UserFields on User {
        email
        role {
            ...RoleFields
        }
        createdAt
        updatedAt
    }
    ${RoleFields}
`

export const UserShortenedFields = gql`
    fragment UserShortenedFields on User {
        email
        role {
            ...RoleShortenedFields
        }
        createdAt
    }
    ${RoleShortenedFields}
`

export const PartnerFields = gql`
    fragment PartnerFields on Partner {
        _id
        url
        name
        logotype {
            ...FileFields
        }
        description
        createdAt
        updatedAt
    }
    ${FileFields}
`

export const PartnerShortenedFields = gql`
    fragment PartnerShortenedFields on Partner {
        _id
        name
    }
`

export const ProductFields = gql`
    fragment ProductFields on Product {
        _id
        name
        body
        image {
            ...FileFields
        }
        startFile {
            ...FileFields
        }
        endFile {
            ...FileFields
        }
        description
        orderNumber
        publishedAt
        createdAt
        updatedAt
    }
    ${FileFields}
`

export const ProductShortenedFields = gql`
    fragment ProductShortenedFields on Product {
        _id
        name
        description
        orderNumber
        publishedAt
        createdAt
    }
`

export const SettingFields = gql`
    fragment SettingFields on Setting {
        _id
        aside {
            title
            navigation {
                title
                path
                color
            }
        }
        navigation {
            title
            path
            color
        }
        contacts {
            emails {
                _id
                label
            }
            phones {
                _id
                label
            }
            addresses {
                _id
                label
            }
            location {
                lat
                lng
            }
            socials {
                title
                path
                icon {
                    ...FileFields
                }
            }
            copyright
        }
        metadata {
            title
            favicon {
                ...FileFields
            }
            logotype {
                ...FileFields
            }
            description
            keywords
        }
    }
    ${FileFields}
`
