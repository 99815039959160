import styled, { css } from 'styled-components'
import React from 'react'

interface Props {
  strong?: boolean
}

const Wrapper = styled.p<Props>`
  ${({ strong }) =>
    strong &&
    css`
      font-weight: bold;
    `}
`

const Text: React.FC<Props> = ({ strong, children }) => (
  <Wrapper strong={strong}>
    {children}
  </Wrapper>
)

export default Text
