import styled from 'styled-components'
import { useQuery } from '@apollo/react-hooks'
import React, { Fragment } from 'react'
import { animated } from 'react-spring'

import { ESection, EColor } from 'types'
import { PARTNERS } from 'graphql/queries'
import { from, to } from 'helpers/springs/spring-move-down'
import { useSpringMoveDown } from 'hooks'
import Waypoint from 'components/waypoint'
import Section from 'components/section'
import Title from 'components/title'

import FOREGROUND from 'assets/images/paints/paint_01.png'
import BORDER_TOP_LEFT from 'assets/images/border-blue-top-left.png'
import BORDER_TOP_RIGHT from 'assets/images/border-blue-top-right.png'
import BORDER_BOTTOM_LEFT from 'assets/images/border-blue-bottom-left.png'
import BORDER_BOTTOM_RIGHT from 'assets/images/border-blue-bottom-right.png'
import BACKGROUND_START from 'assets/images/food/food_06.png'
import BACKGROUND_END from 'assets/images/food/food_10.png'

import PartnerCard from './patner-card'

const Wrapper = styled(Section)``

const PartnerList = styled(animated.div)`
  position: relative;
  display: flex;
  justify-content: center;
  margin: 20px;
  gap: 10px;
  z-index: 6;

  @media (max-width: 998px) {
    flex-direction: column;
    margin: 0 auto;
  }
`

const BackgroundStart = styled.img`
  position: absolute;
  left: -25%;
  bottom: -15%;
  object-fit: contain;
  transform: rotate(45deg);
  z-index: 2;

  @media (max-width: 998px) {
    left: -60%;
  }

  @media (max-width: 480px) {
    width: 30%;
    left: -15%;
    bottom: -10%;
  }
`

const BackgroundEnd = styled.img`
  position: absolute;
  right: -40%;
  bottom: -35%;
  height: 75%;
  object-fit: contain;
  transform: rotate(15deg);
  z-index: 3;

  @media (max-width: 998px) {
    right: -80%;
  }

  @media (max-width: 480px) {
    width: 50%;
    right: -35%;
  }
`

const SectionPartners: React.FC = () => {
  const { data, loading } = useQuery(PARTNERS, {
    fetchPolicy: 'network-only',
  })
  const [springs, api] = useSpringMoveDown()

  if (loading || !data?.partners?.length) return null

  return (
    <Wrapper
      id={ESection.Partners}
      title={
        <Fragment>
          <Title
            color={EColor.Blue}
            foreground={{ _id: '1', path: FOREGROUND, name: 'Foreground' }}
            line
          >
            Купить,
          </Title>
          <Title color={EColor.Blue} line>чтобы есть</Title>
        </Fragment>
      }
      color={EColor.Blue}
      borders={[
        BORDER_TOP_LEFT,
        BORDER_TOP_RIGHT,
        BORDER_BOTTOM_LEFT,
        BORDER_BOTTOM_RIGHT
      ]}
    >
      <Waypoint
        topOffset={250}
        onEnter={() => api.start(to)}
        onLeave={() => api.start(from)}
      >
        <PartnerList style={springs}>
          {data?.partners.map(partner => (
            <PartnerCard key={partner._id} partner={partner} />
          ))}
        </PartnerList>
      </Waypoint>

      <BackgroundStart src={BACKGROUND_START} alt='Background Start' />
      <BackgroundEnd src={BACKGROUND_END} alt='Background End' />
    </Wrapper>
  )
}

export default SectionPartners
