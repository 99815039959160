import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import React from 'react'

import Column from 'components/column'
import Logotype from 'features/logotype'
import ScrollArrow from 'components/scroll-arrow'
import { StretchImage } from 'theme/mixins'
import { ESection } from 'types'

import CIRCLES from 'assets/images/paints/circles.png'
import BACKGROUND from 'assets/images/background-header.png'

const Wrapper = styled(Column)`
  height: 100vh;
  min-height: 845px;

  @media (max-width: 768px) {
    min-height: 320px;
  }
`

const LogotypeContainer = styled(animated.div)`
  position: absolute;
  top: 180px;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  width: 100%;

  @media (max-width: 768px) {
    display: none;
  }
`

const Circles = styled.img`
  ${StretchImage};
  top: 0;
  height: 100vh;
  object-fit: cover;
  z-index: 1;

  @media (max-width: 768px) {
    top: 30px;
  }
`

const Background = styled(animated.img)`
  ${StretchImage};
  top: -200px;
  left: -200px;
  width: calc(100% + 400px);
  height: auto;
  min-height: 100vh;
  object-fit: cover;
  z-index: 3;
`

const SectionHeader: React.FC = () => {
  const LogoAnimation = useSpring({
    to: { opacity: 1, scale: 1 },
    from: { opacity: 0, scale: 0 },
    config: { duration: 500 },
    delay: 200,
  })

  const BackgroundAnimation = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    config: { duration: 500 },
    delay: 150,
  })

  return (
    <Wrapper>
      <LogotypeContainer style={LogoAnimation}>
        <Logotype />
      </LogotypeContainer>

      <ScrollArrow to={ESection.StoryStart} background={<Circles src={CIRCLES} alt='Circles' />} />

      <Background src={BACKGROUND} alt='Background' style={BackgroundAnimation} />
    </Wrapper>
  )
}

export default SectionHeader
