import styled, { css, CSSObject } from 'styled-components'
import React, { useCallback } from 'react'

import { EColor } from 'types'
import { Clear } from 'theme/mixins'
import { scrollTo } from 'helpers'

interface Props {
  to?: string
  style?: CSSObject
  color?: string
  className?: string
  onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const Wrapper = styled.button<Props>`
  ${Clear.button};

  position: relative;
  border: 1px solid var(--color-text);
  padding: 4px 12px;
  cursor: pointer;
  z-index: 5;
`

const Background = styled.div<Pick<Props, 'color'>>`
  position: absolute;
  top: 5px;
  left: -5px;
  display: flex;
  width: calc(100% + 8px);
  height: calc(100% - 6px);
  background: var(--color-gray);
  z-index: 1;

  ${({ color }) =>
    color &&
    css`
      background: ${color};
    `}
`

const Button: React.FC<Props> = ({
  to,
  style,
  color = EColor.Gray,
  className,
  children,
  onClick
}) => {
  const onOverrideClick = useCallback(e => {
    if (to) scrollTo(e, to)
    return onClick?.(e)
  }, [to])

  return (
    <Wrapper style={style} className={className} onClick={onOverrideClick}>
      {children}
      <Background color={color} />
    </Wrapper>
  )
}

export default Button
