import styled from 'styled-components'
import React from 'react'

import { EColor } from 'types'

interface Props {
  color?: EColor
  className?: string
}

const Dashed = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius);
  z-index: 0;

  rect {
    transition: all 150ms ease;
  }
`

const DashedSvg: React.FC<Props> = ({ color = EColor.Gray, className }) => (
  <Dashed width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' className={className}>
    <rect
      width='100%'
      height='100%'
      fill='none'
      rx='4'
      ry='4'
      stroke={`var(--color-${color})`}
      strokeWidth='6'
      strokeDasharray='6, 24'
      strokeDashoffset='0'
      strokeLinecap='square'
    />
  </Dashed>
)

export default DashedSvg
