import styled from 'styled-components'
import { animated } from 'react-spring'
import React from 'react'

import { useSpringsMoveDown } from 'hooks'
import { from, to } from 'helpers/springs/spring-move-down'
import Waypoint from 'components/waypoint'
import Message from 'components/message'
import Column from 'components/column'

import BACKGROUND_END from 'assets/images/food/food_08.png'

import { MESSAGES } from './const'

const Wrapper = styled(Column)`
  position: relative;
  z-index: 10;
  justify-content: center;
  margin-inline: auto;
  padding: 50px;
  width: 600px;
  gap: 15px;

  @media (max-width: 998px) {
    width: max-content;
    margin-block-start: 40px;
    margin-inline: auto;
    padding: 0;
  }
`

const BackgroundEnd = styled.img`
  position: absolute;
  right: -50%;
  bottom: 0;
  height: 55%;
  object-fit: contain;
  z-index: 2;

  @media (max-width: 998px) {
    right: -80%;
  }

  @media (max-width: 480px) {
    display: none;
  }
`

const StoryStartDialogue: React.FC = () => {
  const [springs, api] = useSpringsMoveDown(MESSAGES.length)

  return (
    <Wrapper>
      <Waypoint
        topOffset={250}
        onEnter={() => api.start(to)}
        onLeave={() => api.start(from)}
      >
        <div>
          {springs.map((spring, index) => (
            <animated.div key={index} style={spring}>
              <Message key={MESSAGES[index]?._id} message={MESSAGES[index]} />
            </animated.div>
          ))}
        </div>
      </Waypoint>

      <BackgroundEnd src={BACKGROUND_END} alt='Background End' />
    </Wrapper>
  )
}

export default StoryStartDialogue
