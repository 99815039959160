import styled, { css } from 'styled-components'
import { animated } from 'react-spring'
import React from 'react'

import { EFace, TMessage } from 'types'
import { FlexCenter } from 'theme/mixins'
import { Circle } from 'styled'

import FACE_MAN from 'assets/images/face-man.png'
import FACE_WOMAN from 'assets/images/face-woman.png'
import FACE_TEAM from 'assets/images/face-team.png'

interface Props {
  message: TMessage
  style?: any
  className?: string
}

const Wrapper = styled(animated.div)<{ reverse?: number }>`
  position: relative;
  display: flex;
  width: fit-content;
  gap: 20px;

  ${({ reverse }) =>
    reverse &&
    css`
      flex-direction: row-reverse;
    `};

  @media (max-width: 480px) {
    width: auto;
  }
`

const StyledImage = styled.img`
  width: inherit;
  height: inherit;
  object-fit: contain;
`

const Avatar = styled.div<{ large?: boolean }>`
  ${FlexCenter};

  position: relative;
  z-index: 6;
  width: 78px;
  height: 78px;
  background: var(--color-white);
  box-shadow: var(--box-shadow-m);
  margin-block-start: -20px;
  margin-block-end: 20px;
  border-radius: 50%;
  padding: 6px;

  img {
    width: 70%;
  }

  ${({ large }) =>
    large &&
    css`
      width: 110px;
      height: 110px;

      img {
        width: initial;
      }
    `};

  @media (max-width: 998px) {
    width: 50px;
    height: 50px;
    font-size: 16px;

    ${({ large }) =>
      large &&
      css`
        width: 64px;
        height: 64px;
    `};
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const Content = styled.div<{ reverse?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  background: var(--color-white);
  box-shadow: var(--box-shadow-m);
  border-radius: var(--border-radius);
  height: fit-content;
  padding: 15px 20px;
  font-size: 26px;

  ${({ reverse }) =>
    reverse &&
    css`
      margin-inline-start: 200px;
    `};

  @media (max-width: 998px) {
    padding: 8px 12px;
    font-size: 16px;

    ${({ reverse }) =>
      reverse &&
      css`
        margin-inline-start: 60px;
      `};
  }

  @media (max-width: 480px) {
    max-width: 230px;
  }
`

const Note = styled.p`
  position: relative;
  margin-block-end: 15px;
  padding-inline-start: 15px;
  font-size: 18px;
  color: #A0A0A0;

  @media (max-width: 998px) {
    bottom: 0;
    font-size: 12px;
  }
`

const Background = styled.img`
  position: absolute;
  z-index: 2;

  @media (max-width: 768px) {
    height: 70%;
  }

  @media (max-width: 480px) {
    display: none;
  }
`

const FACES = {
  [EFace.Man]: FACE_MAN,
  [EFace.Woman]: FACE_WOMAN,
  [EFace.Team]: FACE_TEAM
}

const Message: React.FC<Props> = ({ message }) => {
  const reverse = message.face === EFace.Woman;
  return (
    <Wrapper reverse={reverse ? 1 : 0}>
      <Avatar large={message.face === EFace.Team}>
        <Circle>
          <StyledImage src={FACES[message.face]} alt={message.face} />
        </Circle>
      </Avatar>

      <Container>
        <Content reverse={reverse}>
          {message.text}
        </Content>

        {message.note && <Note>{message.note}</Note>}
      </Container>

      {message.background && (
        <Background
          src={message.background?.path}
          alt={message.background?.name}
          style={message.background?.style}
        />
      )}
    </Wrapper>
  )
}

export default Message
