import styled, { css } from 'styled-components'
import React from 'react'

interface Props {
  gap?: number
  className?: string
}

const Wrapper = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: 10px;

  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap}px;
    `}
`

const Column: React.FC<Props> = ({ gap, className, children }) => (
  <Wrapper gap={gap} className={className}>
    {children}
  </Wrapper>
)

export default Column
