import styled from 'styled-components'
import React from 'react'

import { useSettingsContext } from 'context'
import Row from 'components/row'

import SocialLink from './social-link'

const Wrapper = styled(Row)`
  justify-content: flex-end;

  @media (max-width: 480px) {
    justify-content: center;
  }
`

const Socials: React.FC = () => {
  const [settings] = useSettingsContext()

  if (!settings.contacts?.socials?.length) return null

  return (
    <Wrapper>
      {settings.contacts?.socials?.map((social, index) => (
        <SocialLink key={index} icon={social.icon} href={social.path} />
      ))}
    </Wrapper>
  )
}

export default Socials
