import React from 'react'

export const scrollTo = <T extends HTMLElement>(e: React.MouseEvent<T, MouseEvent>, to: string) => {
  e.preventDefault()
  e.stopPropagation()

  const target = document.getElementById(`#${to}`)

  if (target) {
    target.scrollIntoView({ block: 'start', behavior: 'smooth' })
  }
}
