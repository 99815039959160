import styled from 'styled-components'
import { useQuery } from '@apollo/react-hooks'
import React from 'react'

import { EColor, ESection } from 'types'
import { PRODUCTS } from 'graphql/queries'
import Section from 'components/section'
import Title from 'components/title'

import FOREGROUND from 'assets/images/paints/paint_04.png'
import BORDER_TOP_LEFT from 'assets/images/border-skin-top-left.png'
import BORDER_TOP_RIGHT from 'assets/images/border-skin-top-right.png'
import BORDER_BOTTOM_LEFT from 'assets/images/border-skin-bottom-left.png'
import BORDER_BOTTOM_RIGHT from 'assets/images/border-skin-bottom-right.png'

import CatalogCarousel from './catalog-carousel'

const Wrapper = styled(Section)`
  margin-block-end: 350px;
  min-width: 65%;
  z-index: 5;

  @media (max-width: 998px) {
    margin-block-end: 185px;
  }

  @media (max-width: 480px) {
    margin-block-end: 0;
    padding-block-end: 0;
  }
`

const SectionCatalog: React.FC = () => {
  const { data, loading } = useQuery(PRODUCTS, {
    fetchPolicy: 'network-only',
  })

  if (loading || !data?.products?.length) return null

  return (
    <Wrapper
      id={ESection.Catalog}
      title={
        <Title
          color={EColor.Skin}
          foreground={{ _id: '1', path: FOREGROUND, name: 'Foreground' }}
          sliced
          line
        >
          Что есть
        </Title>
      }
      color={EColor.Skin}
      borders={[
        BORDER_TOP_LEFT,
        BORDER_TOP_RIGHT,
        BORDER_BOTTOM_LEFT,
        BORDER_BOTTOM_RIGHT
      ]}
    >
      <CatalogCarousel products={data.products} />
    </Wrapper>
  )
}

export default SectionCatalog
