import React from 'react'

const PhoneBulk: React.FC = () => (
  <svg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M15.3591 11.7607C14.7209 11.6234 14.2161 11.9197 13.7691 12.1784C13.3113 12.4451 12.4409 13.1512 11.9419 12.9705C9.38739 11.9187 6.98479 9.68281 5.94475 7.11803C5.76144 6.60843 6.46428 5.73244 6.72895 5.26926C6.98577 4.82089 7.27593 4.31129 7.14359 3.66837C7.024 3.09063 5.47717 1.12236 4.93019 0.584124C4.56946 0.228591 4.1999 0.0330476 3.82054 0.00144467C2.39428 -0.059786 0.801372 1.8433 0.522 2.29858C-0.177899 3.26939 -0.173978 4.56116 0.533763 6.12748C2.2394 10.3346 8.69043 16.6838 12.9134 18.4536C13.6927 18.818 14.4053 19.0007 15.0454 19.0007C15.6718 19.0007 16.2295 18.8259 16.7089 18.4793C17.0706 18.2709 19.0517 16.5989 18.9997 15.1343C18.9684 14.761 18.7733 14.3877 18.4224 14.0262C17.8881 13.4742 15.9325 11.8812 15.3591 11.7607Z'
      fill='black'
    />
  </svg>
)

export default PhoneBulk
