import styled from 'styled-components'
import React from 'react'

import { TFile } from 'types'
import { FlexCenter } from 'theme/mixins'

interface Props {
  icon: TFile
  href: string
}

const Wrapper = styled.a`
  ${FlexCenter};

  position: relative;
  z-index: 10;
  cursor: pointer;
`

const StyledImage = styled.img`
  width: auto;
  height: 30px;
  object-fit: contain;
`

const SocialLink: React.FC<Props> = ({ icon, href }) => (
  <Wrapper href={href}>
    <StyledImage src={icon?.path} alt={icon?.name} />
  </Wrapper>
)

export default SocialLink
