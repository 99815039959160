import { ApolloClient, InMemoryCache, from } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { onError } from 'apollo-link-error'
import Cookies from 'js-cookie'

import { SECRET } from 'constants/common'

export const errorLink = onError(({ networkError }) => {
  if (networkError) {
    console.log('Network Error')
  }
})

// environment
const NODE_ENV: string = process.env.NODE_ENV || 'development'

const URL = NODE_ENV === 'production' ? 'http://api.zdravilno.ru' : ''

const uploadLink = createUploadLink({
  uri: `${URL}/graphql`,
  headers: {
    'access-token': Cookies.get(SECRET) ?? '',
  },
})

const createApolloClient = () => {
  return new ApolloClient({
    link: from([errorLink, uploadLink]),
    cache: new InMemoryCache(),
  })
}

export default createApolloClient
