import styled from 'styled-components'
import { Navigation } from 'swiper'
import Swiper from 'react-id-swiper'
import React from 'react'

import { TProduct } from 'types'

import 'swiper/css'
import 'swiper/css/navigation'

import Column from 'components/column'
import Row from 'components/row'

interface Props {
  products?: TProduct[]
}

const Wrapper = styled.div`
  position: relative;
  z-index: 10;
  width: 100%;
  margin: 0 auto;

  .swiper-button-next, .swiper-button-prev {
    position: relative;
    top: initial;
    left: initial;
    right: initial;
    margin: 0 auto;
    padding: 5px 35px;
    background: transparent;
    border-radius: 0;
    border: 1px solid black;
    height: 30px;
    color: black;
    margin: 0 auto;
    transition: all 150ms ease;
  }

  .swiper-button-next {
    margin-block-start: 20px;
    right: -35px;
  }
  
  .swiper-button-prev {
    margin-block-start: 12px;
    left: -20px;
  }

  .swiper-button-next:after, .swiper-button-prev:after {
    font-size: 20px;
  }

  .swiper-button-disabled {
    pointer-events: none;
    opacity: 0.25;
  }

  .swiper-slide:not(.swiper-slide-active) {
    height: 0;
  }

  .swiper-slide-active {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-text);
  }

  .swiper-slide:not(.swiper-slide-active) {
    opacity: 0;
  }

  @media (max-width: 480px) {
    .swiper-button-next, .swiper-button-prev {
      display: inline-flex;
      left: 0;
      rigth: 0;
      margin: 0 auto;
      margin-block-start: 40px;
      height: 20px;
    }
  
    .swiper-button-next {
      transform: translateX(calc(100% + 90px));
    }
  
    .swiper-button-prev {
      transform: translateX(calc(-100% + 70px));
    }

    .swiper-button-next:after, .swiper-button-prev:after {
      font-size: 14px;
    }
  }
`

const ProductCard = styled(Column)`
  position: relative;
`

const ProductTitle = styled.div`
  font-size: 28px;

  @media (max-width: 998px) {
    font-size: 24px;
  }
`

const ProductDescription = styled.div`
  font-size: 24px;
  width: 90%;

  @media (max-width: 1200px) {
    width: 80%;
  }

  @media (max-width: 998px) {
    font-size: 20px;
    width: 70%;
  }

  @media (max-width: 480px) {
    font-size: 16px;
    width: initial;
    margin: 0 5%;
  }
`

const ProductContent = styled(Row)`
  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
  }
`

const ProductImage = styled.img`
  width: 500px;
  margin-inline-start: -80px;
  object-fit: contain;

  @media (max-width: 998px) {
    width: 400px;
  }

  @media (max-width: 768px) {
    width: 320px;
  }

  @media (max-width: 480px) {
    width: 100%;
    margin: 0 auto;
  }
`

const ProductBody = styled.div`
  margin-inline-start: 0;
  font-size: 24px;

  @media (max-width: 998px) {
    font-size: 20px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
    margin: 0 5%;
  }
`

const BackgroundStart = styled.img`
  position: absolute;
  left: -25%;
  bottom: -50%;
  object-fit: contain;
  z-index: 2;

  @media (max-width: 1240px) {
    left: -40%;
  }

  @media (max-width: 998px) {
    left: -50%;
  }

  @media (max-width: 480px) {
    display: none;
  }
`

const BackgroundEnd = styled.img`
  position: absolute;
  right: -25%;
  bottom: -50%;
  object-fit: contain;
  transform: rotate(-90deg);
  z-index: 3;

  @media (max-width: 1240px) {
    right: -40%;
  }

  @media (max-width: 998px) {
    right: -50%;
  }

  @media (max-width: 480px) {
    display: none;
  }
`

const CatalogCarousel: React.FC<Props> = ({ products = [] }) => (
  <Wrapper>
    <Swiper
      modules={[Navigation]}
      navigation={{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }}
    >
      {products.map((product, index) => (
        <ProductCard gap={30} key={index}>
          {product.name && (
            <ProductTitle>
              {product.name}
            </ProductTitle>
          )}

          <ProductContent gap={15}>
            {product.image && <ProductImage src={product.image?.path} alt={product.name} />}

            <Column>
              <ProductDescription>
                {product.description}
              </ProductDescription>
              <ProductBody dangerouslySetInnerHTML={{ __html: product.body }} />
            </Column>
          </ProductContent>

          {product.startFile && (
            <BackgroundStart src={product.startFile?.path} alt={product.startFile?.name} />
          )}
          {product.endFile && (
            <BackgroundEnd src={product.endFile?.path} alt={product.endFile?.name} />
          )}
        </ProductCard>
      ))}
    </Swiper>
  </Wrapper>
)

export default CatalogCarousel
