import styled from 'styled-components'
import React, { useMemo } from 'react'

import { useSettingsContext } from 'context'

interface Props {
  className?: string
}

const Wrapper = styled.img`
  max-width: 660px;
  min-width: 160px;

  @media (max-width: 998px) {
    max-width: initial;
  }
`

const Logotype: React.FC<Props> = ({ className }) => {
  const [settings] = useSettingsContext()

  const logotype = useMemo(() => settings?.metadata?.logotype, [settings])

  if (!logotype) return null

  return (
    <Wrapper src={logotype?.path} alt='Logotype' className={className} />
  )
}

export default Logotype
