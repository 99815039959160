import styled, { css } from 'styled-components'
import { useSpring, animated } from 'react-spring'
import React from 'react'

import { EColor, TFile, TTypographyRotation } from 'types'
import { from, to } from 'helpers/springs/spring-move-down'
import Waypoint from 'components/waypoint'

type TFileProps = {
  large?: boolean
}

interface Props {
  color?: EColor
  line?: boolean
  sliced?: boolean
  foreground?: Partial<TFile> & TFileProps
  background?: Partial<TFile> & TFileProps
  rotation?: boolean | TTypographyRotation
}

const Wrapper = styled(animated.h1)<{ line?: number, sliced?: number }>`
  position: relative;
  z-index: 2;
  display: flex;
  font-weight: 700;
  font-size: 86px;
  line-height: 85%;
  text-align: center;
  width: max-content;
  margin-block: 0;
  margin-inline: auto;

  ${({ color }) =>
    color &&
    css`
      color: var(--color-black);

      &:hover {
        color: var(--color-black);
      }
    `};

  ${({ sliced }) =>
    sliced &&
    css`
      max-width: 450px;

      @media (max-width: 998px) {
        max-width: initial;
      }
    `};

  @media (max-width: 998px) {
    font-size: 36px;
  }
`

const Line = styled.span<Pick<Props, 'color'>>`
  position: absolute;
  top: 25px;
  left: -15px;
  width: 100%;
  height: 50%;
  border-radius: var(--border-radius);
  background: var(--color-gray);
  z-index: 1;

  ${({ color }) =>
    color &&
    css`
      background: var(--color-${color});
    `};

  @media (max-width: 998px) {
    top: 8px;
    left: -8px;
  }
`

const Foreground = styled.img<TFileProps>`
  position: absolute;
  top: -50%;
  left: -50%;
  z-index: 1;
  width: 200%;
  object-fit: contain;

  ${({ large }) =>
    large &&
    css`
      top: -100%;
      left: -100%;
      width: 300%;
    `};
`

const Background = styled.img<TFileProps>`
  position: absolute;
  top: 5px;
  z-index: 0;
  height: 100%;
  object-fit: contain;
`

const Title: React.FC<Props> = ({
  line,
  color,
  sliced,
  foreground,
  background,
  children
}) => {
  const [spring, api] = useSpring(() => ({
    to: { opacity: 1 },
    from: { opacity: 0 },
    config: { duration: 1000 },
    delay: 1000,
  }))

  return (
    <Waypoint
      onEnter={() => api.start(to)}
      onLeave={() => api.start(from)}
    >
      <Wrapper line={line ? 1 : 0} color={color} style={spring} sliced={sliced ? 1 : 0}>
        {children}

        {line && <Line color={color} />}

        {foreground && <Foreground src={foreground.path} alt={foreground.name} large={foreground.large} />}
        {background && <Background src={background.path} alt={background.name} large={background.large} />}
      </Wrapper>
    </Waypoint>
  )
}

export default Title
