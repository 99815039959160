import styled, { css } from 'styled-components'
import React, { useCallback } from 'react'

import { TFile } from 'types'
import Text from 'components/text'
import { Circle } from 'styled'

interface Props {
  icon?: React.ReactNode | TFile
  href?: string
  value: string
  className?: string
}

const Wrapper = styled.div<{ clickable?: boolean }>`
  display: flex;
  align-items: center;
  background: var(--color-white);
  box-shadow: var(--box-shadow-s);
  border-radius: var(--border-radius);
  font-size: 22px;
  padding: 10px 20px;
  flex-grow: 1;
  gap: 10px;
  transition: opacity 150ms ease;

  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;

      &:hover {
        opacity: .65;
      }
    `};

  @media (max-width: 998px) {
    font-size: 16px;
  }
`

const StyledCircle = styled(Circle)`
  width: 34px;
  height: 34px;
`

const StyledIcon = styled.img`
  width: 26px;
  height: 26px;
  object-fit: contain;
`

const DefinitionCard: React.FC<Props> = ({ icon, href, value, className }) => {
  const isReactElement = React.isValidElement(icon)

  const onClick = useCallback(() => {
    if (href) {
      window.open(href, '_blank')
    }
  }, [href])

  return (
    <Wrapper className={className} clickable={Boolean(href)} onClick={onClick}>
      {icon && (
        <StyledCircle>
          {isReactElement ? icon : <StyledIcon src={(icon as TFile).path} alt={(icon as TFile).name} />}
        </StyledCircle>
      )}
      <Text>{value}</Text>
    </Wrapper>
  )
}

export default DefinitionCard
