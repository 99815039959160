import styled, { createGlobalStyle } from 'styled-components'

import { Clear, ClearIndents, FlexCenter } from 'theme/mixins'

export const GlobalStyle = createGlobalStyle`
  :root {
    --color-white: #fff;
    --color-black: #000;
    --color-text: #232323;
    --color-background: #FAFAFA;
    --color-purple: rgba(124, 122, 221, 0.35);
    --color-red: rgba(221, 122, 122, 0.35);
    --color-green: rgba(122, 221, 161, 0.35);
    --color-skin: rgba(221, 194, 122, 0.35);
    --color-blue: rgba(122, 149, 221, 0.35);
    --color-gray: rgba(203, 203, 203, 0.35);

    --font-size: 16px;
    --font-family: 'Sitka Display', serif;

    --box-shadow-s: 0px 20px 50px rgba(214, 214, 214, 0.15);
    --box-shadow-m: 0px 20px 50px rgba(214, 214, 214, 0.5);

    --border-radius: 2px;

    --section-border-width-s: 25px;
    --section-border-width-m: 45px;

    --circle-size-s: 78px;
    --circle-size-m: 112px;
    --avatar-size-s: 50px;
    --avatar-size-m: 105px;
  }

  html, body {
    ${ClearIndents};

    font-size: var(--font-size);
    font-family: var(--font-family);
    font-weight: 400;
    background: #fafafa;
    overflow-x: hidden;
  }

  a {
    ${Clear.a};
  }

  p {
    ${ClearIndents};
  }

  ul {
    ${Clear.ul};
  }

  .leaflet-control-attribution a {
    display: none;
  }
`

export const Wrapper = styled.main`
  overflow: hidden;
`

export const Circle = styled.div`
  ${FlexCenter};

  position: relative;
  z-index: 7;
  height: inherit;
  min-width: 38px;
  min-height: 38px;
  border-radius: 50%;
  background: #E9E9E9;
`
