import { useSpring } from 'react-spring'

export const useSpringMoveDown = () => {
  return useSpring(i =>({
    to: {
      opacity: 1,
      translateY: 0,
      delay: i * 100,
      config: { duration: 500 }
    },
    from: {
      opacity: 0,
      translateY: -50,
      delay: i * 100,
      config: { duration: 500 }
    }
  }))
}
