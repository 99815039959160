import { EFace } from 'types'

import BACKGROUND_CROWN from 'assets/images/paints/paint_03.png'

export const MESSAGE = {
  _id: '1',
  text: 'Примерно так у нас* случилась мотивация на создание Здравильных каш, которые отвечали бы следующим требованиям:',
  note: '*Мы - это молодой профессиональный коллектив завода с 21 летним опытом производства продуктов быстро доставляющих удовольствие',
  background: {
    name: 'crown',
    path: BACKGROUND_CROWN,
    style: {
      top: -90,
      left: -45
    }
  },
  face: EFace.Team
}
