import styled from 'styled-components'
import React from 'react'

import Title from 'components/title'

import FOREGROUND from 'assets/images/paints/paint_02.png'
import SCROLL_ARROW from 'assets/images/paints/scroll_arrow.png'

const Wrapper = styled.div`
  position: relative;
  top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 460px;

  @media (max-width: 998px) {
    height: 260px;
  }
`

const Background = styled.img`
  position: absolute;
  top: -120px;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 1;

  @media (max-width: 998px) {
    top: -40px;
    height: 160px;
  }
`

const SectionStoryTransition: React.FC = () => (
  <Wrapper>
    <Title>Титаническая</Title>
    <Title foreground={{ _id: '1', path: FOREGROUND, name: 'Foreground', large: true }}>работа</Title>

    <Background src={SCROLL_ARROW} alt='Titanic Work' />
  </Wrapper>
)

export default SectionStoryTransition
