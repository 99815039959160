export const to = (i: number) => ({
  opacity: 1,
  translateY: 0,
  delay: i * 100,
  config: { duration: 500 }
})

export const from = (i: number) => ({
  opacity: 0,
  translateY: -50,
  delay: i * 100,
  config: { duration: 500 }
})
