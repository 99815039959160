import { useQuery } from '@apollo/react-hooks'
import { SETTINGS } from 'graphql/queries'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

import Navigation from 'features/navigation'
import SectionHeader from 'features/section-header'
import SectionStoryStart from 'features/section-story-start'
import SectionStoryTransition from 'features/section-story-transition'
import SectionStoryEnd from 'features/section-story-end'
import SectionPartners from 'features/section-partners'
import SectionContacts from 'features/section-contacts'
import SectionCatalog from 'features/section-catalog'
import { Wrapper, GlobalStyle } from 'styled'
import { useSettingsContext } from 'context'
import { TITLE } from 'constants/common'

import 'theme/global.css'

const App: React.FC = () => {
  const [, setSettings] = useSettingsContext()
  const { data, loading } = useQuery(SETTINGS, {
      fetchPolicy: 'network-only',
  })

  useEffect(() => {
      if (data?.settings) {
          setSettings(data.settings)
      }
  }, [data])

  if (loading) return null

  return (
    <Wrapper>
      <Helmet>
        <meta name='theme-color' content='#fafafa' />
        <meta name='keywords' content={data?.settings?.metadata?.keywords || ''} />
        <meta name='description' content={data?.settings?.metadata?.description || ''} />
        <link rel='icon' type='image/x-icon' href={data?.settings?.metadata?.favicon?.path} />
        <title>{data?.settings?.metadata?.title || TITLE}</title>
      </Helmet>

      <Navigation />

      <SectionHeader />
      <SectionStoryStart />
      <SectionStoryTransition />
      <SectionStoryEnd />
      <SectionCatalog />
      <SectionPartners />
      <SectionContacts />

      <GlobalStyle />
    </Wrapper>
  )
}

export default App
