import styled from 'styled-components'
import { animated } from 'react-spring'
import React from 'react'

import { useSpringsMoveDown } from 'hooks'
import { from, to } from 'helpers/springs/spring-move-down'
import DefinitionCard from 'components/definition-card'
import Waypoint from 'components/waypoint'

import ICON_FOOD_1 from 'assets/images/icon-food-1.png'
import ICON_FOOD_2 from 'assets/images/icon-food-2.png'
import ICON_FOOD_3 from 'assets/images/icon-food-3.png'
import ICON_FOOD_4 from 'assets/images/icon-food-4.png'

const cards = [
  { icon: { _id: '1', name: '', path: ICON_FOOD_1 }, value: 'Ничего вредного' },
  { icon: { _id: '2', name: '', path: ICON_FOOD_2 }, value: 'Максимум пользы' },
  { icon: { _id: '3', name: '', path: ICON_FOOD_3 }, value: 'Максимум вкуса' },
  { icon: { _id: '4', name: '', path: ICON_FOOD_4 }, value: 'Скорость и удобство употребления' }
]

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 600px;
  gap: 30px;

  @media (max-width: 998px) {
    display: flex;
    flex-direction: column;
    width: max-content;
    margin: 0 auto;
  }
`

const StyledCard = styled(DefinitionCard)`
  height: 75px;

  @media (max-width: 998px) {
    height: auto;
  }
`

const ProductStructures = () => {
  const [springs, api] = useSpringsMoveDown(cards.length)

  return (
    <Waypoint
      topOffset={250}
      onEnter={() => api.start(to)}
      onLeave={() => api.start(from)}
    >
      <Wrapper>
        {springs.map((spring, index) => {
          const card = cards[index]
          return (
            <animated.div style={spring} key={index}>
              <StyledCard key={index} icon={card.icon} value={card.value} />
            </animated.div>
          )
        })}
      </Wrapper>
    </Waypoint>
  )
}

export default ProductStructures
