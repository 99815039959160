import styled from 'styled-components'
import { animated } from 'react-spring'
import React from 'react'

import { useSpringMoveDown } from 'hooks'
import { from, to } from 'helpers/springs/spring-move-down'
import Waypoint from 'components/waypoint'
import Message from 'components/message'
import Column from 'components/column'

import { MESSAGE } from './const'
import ProductStructures from './product-structures'

const Wrapper = styled(Column)`
  width: 680px;
  margin-inline: auto;
  padding: 50px;

  @media (max-width: 998px) {
    width: fit-content;
    margin-block-start: 40px;
    margin-inline: auto;
    padding: 0;
  }
`

const StoryStartTeam: React.FC = () => {
  const [springs, api] = useSpringMoveDown()

  return (
    <Wrapper gap={30}>
      <Waypoint
        onEnter={() => api.start(to)}
        onLeave={() => api.start(from)}
      >
        <animated.div style={springs}>
          <Message message={MESSAGE} />
        </animated.div>
      </Waypoint>

      <ProductStructures />
    </Wrapper>
  )
}

export default StoryStartTeam
