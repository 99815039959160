import { EFace, TMessage } from 'types'

import BACKGROUND_LINES from 'assets/images/paints/paint_05.png'
import BACKGROUND_ARROW from 'assets/images/paints/paint_06.png'

export const MESSAGES: TMessage[] = [
  {
    _id: '1',
    text: 'Оно ведь как?!',
    face: EFace.Man
  },
  {
    _id: '2',
    text: 'Как?',
    face: EFace.Woman,
    background: {
      name: 'lines',
      path: BACKGROUND_LINES,
      style: {
        top: -90,
        right: -100
      }
    }
  },
  {
    _id: '3',
    text: 'Или вкусно или полезно*',
    note: '*Речь о продуктах быстрого питания',
    face: EFace.Man
  },
  {
    _id: '4',
    text: 'Нууу... В целом да!',
    face: EFace.Woman
  },
  {
    _id: '5',
    text: 'Вооот! С этим нужно что-то делать...',
    face: EFace.Man,
    background: {
      name: 'arrow',
      path: BACKGROUND_ARROW,
      style: {
        top: 35,
        right: -120
      }
    }
  },
]
