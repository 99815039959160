import styled from 'styled-components'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import React, { useMemo } from 'react'
import { useSettingsContext } from 'context'

const Wrapper = styled.div`
  position: relative;
  z-index: 10;
  width: 100%;
  height: 350px;

  .leaflet-container {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 998px) {
    height: 240px;
  }
`

const ContactMap: React.FC = () => {
  const [settings] = useSettingsContext()
  const location = settings?.contacts?.location

  const position = useMemo(() => ({
    lat: parseFloat(location?.lat ?? 0),
    lng: parseFloat(location?.lng ?? 0)
  }), [location])

  if (!location) return null

  return (
    <Wrapper>
      <MapContainer
        zoom={16}
        maxZoom={20}
        center={position}
        easeLinearity={0.35}
        attributionControl
        zoomControl
        doubleClickZoom
        scrollWheelZoom
        dragging
      >
        <TileLayer
          url='http://{s}.tile.osm.org/{z}/{x}/{y}.png'
        />
        <Marker position={position}>
          <Popup>
            Popup for any custom information.
          </Popup>
        </Marker>
      </MapContainer>
    </Wrapper>
  )
}

export default ContactMap
