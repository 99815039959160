import styled from 'styled-components'
import React, { useCallback } from 'react'

import { scrollTo } from 'helpers'

interface Props {
  to: string
  className?: string
  onClick?: () => void
}

const Wrapper = styled.a``

const Anchor: React.FC<Props> = ({ to, className, children, onClick }) => {
  const onOverrideClick = useCallback(e => {
    if (to) scrollTo(e, to)
    return onClick?.()
  }, [to])

  return (
    <Wrapper href='' className={className} onClick={onOverrideClick}>
      {children}
    </Wrapper>
  )
}

export default Anchor
