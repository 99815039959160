import styled, { createGlobalStyle, css } from 'styled-components'
import { useSpring, animated } from 'react-spring'
import React, { useCallback, useEffect, useState } from 'react'

import Row from 'components/row'
import Text from 'components/text'
import Column from 'components/column'
import Button from 'components/button'
import Anchor from 'components/anchor'
import Logotype from 'features/logotype'
import { useSettingsContext } from 'context'
import { Clear, FlexCenter } from 'theme/mixins'
import { useMediaQuery } from 'hooks'

const GlobalStyle = createGlobalStyle<{ isVisibility?: boolean }>`
  ${({ isVisibility }) =>
    isVisibility &&
    css`
      html, body {
        overflow: hidden;
      }
    `}
`

const Wrapper = styled(animated.div)`
  position: absolute;
  top: 5%;
  left: 18%;
  z-index: 100;
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: 789px) {
    top: 25px;
    left: 25px;
    width: calc(100% - 50px);
  }

  @media (max-width: 480px) {
    top: 15px;
    left: 15px;
    width: calc(100% - 30px);
  }
`

const MobileMarkup = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: none;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 15px;

  @media (max-width: 789px) {
    display: flex;
  }
`

const StyledLogotype = styled(Logotype)`
  height: 50px;
  object-fit: contain;
`

const Content = styled(animated.div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 36%);

  @media (max-width: 789px) {
    position: fixed;
    top: 0;
    left: 0;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 30px);
    height: calc(100vh - 30px);
    background: var(--color-white);
    padding: 15px;
    gap: 35px;

    button {
      font-size: 20px;
      text-align: center;
    }

    a, p {
      font-size: 26px;
      text-align: center;
    }
  }
`

const Aside = styled(Row)`
  position: relative;
  z-index: 100;

  @media (max-width: 998px) {
    gap: 15px;
  }

  @media (max-width: 789px) {
    flex-direction: column;
  }
`

const StyledButton = styled.button<{ isCollapsed?: boolean }>`
  ${Clear.button};
  ${FlexCenter};

  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 1px solid black;
  transition: all 150ms ease-in-out;
  cursor: pointer;

  & > span {
    position: relative;
    width: 12px;
    height: 2px;
    background: var(--color-black);
    border-radius: var(--border-radius);
    transition: all 150ms ease-in-out;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      display: flex;
      width: 12px;
      height: 2px;
      background: var(--color-black);
      border-radius: var(--border-radius);
      transition: all 150ms ease-in-out;
    }

    &::before {
      top: 5px;
    }

    &::after {
      top: -5px;
    }

    ${({ isCollapsed }) =>
      isCollapsed &&
      css`
        background: transparent;

        &::before {
          top: 0;
          transform: rotate(45deg);
        }

        &::after {
          top: 0;
          transform: rotate(-45deg);
        }
      `}
  }

  &:hover {
    background: var(--color-black);

    & > span,
    & > span::before,
    & > span::after {
      background: var(--color-white);
    }

    ${({ isCollapsed }) =>
      isCollapsed &&
      css`
        & > span {
          background: transparent;
        }
      `}
  }

  @media (max-width: 480px) {
    top: 5px;
    right: 15px;
  }
`

const Navigation: React.FC = () => {
  const [isVisibility, setVisibility] = useState(false)
  const isMobile = useMediaQuery('(max-width: 789px)')
  const [settings] = useSettingsContext()

  const AnimationMenu = useSpring({
    to: { opacity: 1, translateY: 0 },
    from: { opacity: 0, translateY: -50 },
    config: { duration: 700 },
    delay: 50,
  })

  const [AnimationFade, api] = useSpring(() => ({ opacity: 0 }))

  const onToggleVisibility = useCallback(() => setVisibility(!isVisibility), [isVisibility])

  const onNavigateTo = useCallback(() => setVisibility(false), [isVisibility])

  useEffect(() => {
    if (!isMobile || isVisibility) {
      // @ts-ignore
      api.start({
        opacity: 1,
        visibility: 'visible',
      })
    } else {
      // @ts-ignore
      api.start({
        opacity: 0,
        visibility: 'hidden',
      })
    }
  }, [api, isMobile, isVisibility])

  return (
    <Wrapper style={AnimationMenu}>
      <MobileMarkup>
        <StyledLogotype />

        <StyledButton isCollapsed={isVisibility} onClick={onToggleVisibility}>
          <span />
        </StyledButton>
      </MobileMarkup>

      <Content style={AnimationFade}>
        {settings.aside && (
          <Column>
            <Text strong>{settings.aside?.title}</Text>
            {settings.aside?.navigation && (
              <Row gap={15}>
                {settings.aside?.navigation?.map((link, index) => (
                  <Button
                    key={index}
                    to={link.path}
                    color={link.color}
                    onClick={onNavigateTo}
                  >
                    {link.title}
                  </Button>
                ))}
              </Row>
            )}
          </Column>
        )}

        {settings.navigation && (
          <Aside gap={35}>
            {settings.navigation?.map((link, index) => (
              <Anchor key={index} to={link.path} onClick={onNavigateTo}>{link.title}</Anchor>
            ))}
          </Aside>
        )}
      </Content>

      <GlobalStyle isVisibility={isVisibility} />
    </Wrapper>
  )
}

export default Navigation
