import gql from 'graphql-tag'

import { FileFields, ProductFields, PartnerFields, SettingFields } from './fragments'

export const FILE = gql`
    query file($_id: ID!) {
        file(_id: $_id) {
            ...FileFields
        }
    }
    ${FileFields}
`

export const FILES = gql`
    query files {
        files {
            ...FileFields
        }
    }
    ${FileFields}
`

export const PRODUCTS = gql`
    query products {
        products {
            ...ProductFields
        }
    }
    ${ProductFields}
`

export const PARTNERS = gql`
    query partners {
        partners {
            ...PartnerFields
        }
    }
    ${PartnerFields}
`

export const SETTINGS = gql`
    query settings {
        settings {
            ...SettingFields
        }
    }
    ${SettingFields}
`
