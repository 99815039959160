import styled, { css } from 'styled-components'
import { useSpring, animated } from 'react-spring'
import React from 'react'

import { ESection, EColor } from 'types'
import { from, to } from 'helpers/springs/spring-move-down'
import Waypoint from 'components/waypoint'

interface Props {
  id: ESection
  title?: React.ReactNode
  color?: EColor
  borders?: string[]
  className?: string
}

const BorderStyle = css`
  position: absolute;
  object-fit: contain;
  z-index: 1;

  @media (max-width: 998px) {
    width: 15%;
  }
`

const Wrapper = styled.section<{ withTitle?: boolean }>`
  position: relative;
  width: max-content;
  max-width: 90%;
  min-width: 50%;
  min-height: 265px;
  margin: 0 auto;
  padding: 50px;
  z-index: 2;

  ${({ withTitle }) =>
    withTitle &&
    css`
      margin-block-start: 200px;
      padding-block: 100px;
    `};

  @media (max-width: 998px) {
    padding: 15px;

    ${({ withTitle }) =>
      withTitle &&
      css`
        margin-block-start: 85px;
        padding-block: 85px;
      `};
  }
`

const Title = styled.div`
  position: absolute;
  margin-block-start: -200px;
  padding-block-start: 100px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  z-index: 2;

  @media (max-width: 998px) {
    margin-block-start: -100px;
    padding-block-start: 100px;
    top: 0;
  }
`

const BorderTopLeft = styled.img`
  ${BorderStyle};
  top: 0;
  left: 0;
`

const BorderTopRight = styled.img`
  ${BorderStyle};
  top: 0;
  right: 0;
`

const BorderBottomLeft = styled.img`
  ${BorderStyle};
  bottom: 0;
  left: 0;
`

const BorderBottomRight = styled.img`
  ${BorderStyle};
  bottom: 0;
  right: 0;
`

const Section: React.FC<Props> = ({
  id,
  title,
  borders = [],
  className,
  children
}) => {
  const [spring, api] = useSpring(() => ({
    to: { opacity: 1 },
    from: { opacity: 0 },
    config: { duration: 500 },
    delay: 150,
  }))

  const [
    BORDER_TOP_LEFT,
    BORDER_TOP_RIGHT,
    BORDER_BOTTOM_LEFT,
    BORDER_BOTTOM_RIGHT
  ] = borders

  return (
    <Waypoint
      onEnter={() => api.start(to)}
      onLeave={() => api.start(from)}
    >
      <Wrapper id={`#${id}`} className={className} withTitle={!!title}>
        {title && <Title>{title}</Title>}

        {children}

        {borders.length && (
          <animated.div style={spring}>
            <BorderTopLeft src={BORDER_TOP_LEFT} alt='Border Top Left' />
            <BorderTopRight src={BORDER_TOP_RIGHT} alt='Border Top Right' />
            <BorderBottomLeft src={BORDER_BOTTOM_LEFT} alt='Border Bottom Left' />
            <BorderBottomRight src={BORDER_BOTTOM_RIGHT} alt='Border Bottom Right' />
          </animated.div>
        )}
      </Wrapper>
    </Waypoint>
  )
}

export default Section
