export const formatPhone = (value: string) => {
  const filtered = String(value).replace(/\D/g, '')
  const match = filtered.match(/^(\d|)?(\d{3})(\d{3})(\d{2})(\d{2})$/)

  if (match) {
    const code = (match[1] ? '+7 ' : '');
    return `${code}(${match[2]}) ${match[3]} ${match[4]}-${match[5]}`
  }

  return value
}
