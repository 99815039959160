import styled from 'styled-components'
import React from 'react'

import { TPartner } from 'types'
import { FlexCenter } from 'theme/mixins'
import Dashed from 'components/dashed'

interface Props {
  partner: TPartner
}

const StyledImage = styled.img`
  width: 80%;
  height: 60%;
  object-fit: contain;
  border-radius: var(--border-radius);
`

const StyledDashed = styled(Dashed)`
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all 300ms ease;
`

const Wrapper = styled.a`
  ${FlexCenter};

  position: relative;
  width: 320px;
  height: 170px;
  margin: 20px;
  cursor: pointer;
  border-radius: var(--border-radius);
  transition: all 300ms ease;

  &:hover {
    background: var(--color-white);
    box-shadow: var(--box-shadow-m);

    ${StyledDashed} {
      opacity: 0;
    }
  }

  @media (max-width: 998px) {
    margin: 0 auto;
  }
`

const PartnerCard: React.FC<Props> = ({ partner }) => (
  <Wrapper title={partner.tooltip} href={partner.url} target='_blank'>
    <StyledImage src={partner.logotype?.path} alt={partner.tooltip} />
    <StyledDashed />
  </Wrapper>
)

export default PartnerCard
