import styled from 'styled-components'
import React from 'react'

import { EColor } from 'types'
import Dashed from 'components/dashed/dashed'

interface Props {
  color?: EColor
}

const StyledDashed = styled(Dashed)`
  position: absolute;
  top: 20px;
  left: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  transition: all 300ms ease;

  @media (max-width: 998px) {
    top: 10px;
    left: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
  }
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: var(--color-white);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-s);
  font-size: 26px;
  padding: 45px;
  width: 320px;

  @media (max-width: 998px) {
    font-size: 16px;
    padding: 25px;
    width: 240px;
  }
`

const AdvantageCard: React.FC<Props> = ({ color, children }) => (
  <Wrapper>
    {children}

    <StyledDashed color={color} />
  </Wrapper>
)

export default AdvantageCard
