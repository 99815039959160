import styled from 'styled-components'
import React from 'react'

import Anchor from 'components/anchor'

import ARROW from 'assets/images/arrow.png'

interface Props {
  to: string
  background?: React.ReactNode
}

const Wrapper = styled.div``

const StyledAnchor = styled(Anchor)`
  position: absolute;
  top: calc(50% + 75px);
  left: calc(50% - 92px);
  width: 160px;
  height: 160px;
  border-radius: 50%;
  border: 1px dashed var(--color-gray);
  z-index: 11;

  @media (max-width: 998px) {
    top: calc(50% + 46px);
    left: calc(50% - 64px);
    width: 110px;
    height: 110px;
  }
`

const StyledImage = styled.img`
  width: inherit;
  height: inherit;
`

const ScrollArrow: React.FC<Props> = ({ to, background }) => (
  <Wrapper>
    <StyledAnchor to={to}>
      <StyledImage src={ARROW} alt='Arrow' />
    </StyledAnchor>

    {background}
  </Wrapper>
)

export default ScrollArrow
