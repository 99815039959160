import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import { ApolloProvider } from '@apollo/react-hooks'

import history from 'store/history'
import createApolloClient from 'api/client'
import { SettingsProvider } from 'context'

import App from './App'

const client = createApolloClient()

ReactDOM.render(
  <ApolloProvider client={client}>
    <SettingsProvider>
      <Router history={history}>
        <App />
      </Router>
    </SettingsProvider>
  </ApolloProvider>,
  document.getElementById('root'),
)
