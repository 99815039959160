import styled from 'styled-components'
import { animated } from 'react-spring'
import React from 'react'

import { EColor, ESection } from 'types'
import { useSpringsMoveDown } from 'hooks'
import { from, to } from 'helpers/springs/spring-move-down'
import Waypoint from 'components/waypoint'
import Section from 'components/section'

import BORDER_TOP_LEFT from 'assets/images/border-green-top-left.png'
import BORDER_TOP_RIGHT from 'assets/images/border-green-top-right.png'
import BORDER_BOTTOM_LEFT from 'assets/images/border-green-bottom-left.png'
import BORDER_BOTTOM_RIGHT from 'assets/images/border-green-bottom-right.png'
import BACKGROUND_TOP_START from 'assets/images/food/food_03.png'
import BACKGROUND_TOP_END from 'assets/images/food/food_11.png'
import BACKGROUND_BOTTOM_START from 'assets/images/food/food_02.png'
import BACKGROUND_BOTTOM_END from 'assets/images/food/food_04.png'

import AdvantageCard from './advantage-card'

const cards = [
  'В ZDRAVILNO нет: Ароматизаторов, сахара, подсластителей, соли, усилителей вкуса',
  'Овсяные хлопья с бережной обработкой паром, отруби, омега 3-6, протеин, имбирь, кедровые орешки',
  'Чтобы ZDRAVILNO, была вкусной, основного вкусового ингридиента добавляем очень много (вплоть до 30% от веса каши), например, абрикоса, финика, яблока, апельсинов, медовых гранул, кедровых орешков',
  'ZDRAVILNO осталась кашей быстрого приготовления: готовность 3-5 минут просто добавить кипяток. Упаковка удобна для разных ситуаций, и для дома и для дороги и для отеля'
]

const Wrapper = styled(Section)``

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  width: max-content;
  margin: 0 auto;
  padding: 50px;
  gap: 15px;

  @media (max-width: 998px) {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0 auto;
  }
`

const BackgroundTopStart = styled.img`
  position: absolute;
  top: -25%;
  left: -45%;
  height: 65%;
  object-fit: contain;
  z-index: 2;

  @media (max-width: 998px) {
    top: -5%;
    left: -60%;
  }

  @media (max-width: 480px) {
    width: 30%;
    left: -15%;
    bottom: -10%;
  }
`

const BackgroundTopEnd = styled.img`
  position: absolute;
  top: -25%;
  right: -35%;
  height: 50%;
  object-fit: contain;
  z-index: 2;

  @media (max-width: 998px) {
    right: -80%;
  }

  @media (max-width: 480px) {
    width: 50%;
    right: -35%;
  }
`

const BackgroundBottomStart = styled.img`
  position: absolute;
  left: -15%;
  bottom: -5%;
  height: 15%;
  object-fit: contain;
  z-index: 2;

  @media (max-width: 998px) {
    top: -5%;
    left: -60%;
  }

  @media (max-width: 480px) {
    width: 30%;
    left: -15%;
    bottom: -10%;
  }
`

const BackgroundBottomEnd = styled.img`
  position: absolute;
  right: -25%;
  bottom: -15%;
  height: 45%;
  object-fit: contain;
  z-index: 2;

  @media (max-width: 998px) {
    right: -80%;
  }

  @media (max-width: 480px) {
    width: 50%;
    right: -35%;
  }
`

const SectionStoryEnd: React.FC = () => {
  const [springs, api] = useSpringsMoveDown(cards.length)

  return (
    <Wrapper
      id={ESection.StoryEnd}
      color={EColor.Green}
      borders={[
        BORDER_TOP_LEFT,
        BORDER_TOP_RIGHT,
        BORDER_BOTTOM_LEFT,
        BORDER_BOTTOM_RIGHT
      ]}
    >
      <Waypoint
        topOffset={250}
        onEnter={() => api.start(to)}
        onLeave={() => api.start(from)}
      >
        <Container>
          {springs.map((spring, index) => {
            const card = cards[index]
            return (
              <animated.div key={index} style={spring}>
                <AdvantageCard key={index} color={EColor.Green}>
                  {card}
                </AdvantageCard>
              </animated.div>
            )
          })}
        </Container>
      </Waypoint>

      <BackgroundTopStart src={BACKGROUND_TOP_START} alt='Background Start' />
      <BackgroundTopEnd src={BACKGROUND_TOP_END} alt='Background End' />
      <BackgroundBottomStart src={BACKGROUND_BOTTOM_START} alt='Background Start' />
      <BackgroundBottomEnd src={BACKGROUND_BOTTOM_END} alt='Background End' />
    </Wrapper>
  )
}

export default SectionStoryEnd
