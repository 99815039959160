import styled from 'styled-components'
import React from 'react'

import { EColor, ESection } from 'types'
import Section from 'components/section'
import Title from 'components/title'

import BORDER_TOP_LEFT from 'assets/images/border-purple-top-left.png'
import BORDER_TOP_RIGHT from 'assets/images/border-purple-top-right.png'
import BORDER_BOTTOM_LEFT from 'assets/images/border-purple-bottom-left.png'
import BORDER_BOTTOM_RIGHT from 'assets/images/border-purple-bottom-right.png'
import BACKGROUND_TOP_START from 'assets/images/food/food_05.png'
import BACKGROUND_TOP_END from 'assets/images/food/food_12.png'
import BACKGROUND_BOTTOM_START from 'assets/images/food/food_09.png'
import BACKGROUND_BOTTOM_END from 'assets/images/food/food_14.png'

import StoryStartDialogue from './story-start-dialogue'
import StoryStartTeam from './story-start-team'

const Wrapper = styled(Section)`
  margin-block-start: 0px;
  padding-block: 200px;

  @media (max-width: 998px) {
    margin-block-start: 0;
    padding-block: 65px 15px;
  }
`

const BackgroundTopStart = styled.img`
  position: absolute;
  top: 5%;
  left: -10%;
  height: 15%;
  object-fit: contain;
  z-index: 2;

  @media (max-width: 998px) {
    top: -5%;
    left: -60%;
  }

  @media (max-width: 480px) {
    width: 30%;
    left: -15%;
    bottom: -10%;
  }
`

const BackgroundTopEnd = styled.img`
  position: absolute;
  top: 5%;
  right: -15%;
  height: 22%;
  object-fit: contain;
  z-index: 2;

  @media (max-width: 998px) {
    right: -80%;
  }

  @media (max-width: 480px) {
    width: 50%;
    right: -35%;
  }
`

const BackgroundBottomStart = styled.img`
  position: absolute;
  left: -20%;
  bottom: -5%;
  height: 15%;
  object-fit: contain;
  z-index: 2;

  @media (max-width: 998px) {
    top: -5%;
    left: -60%;
  }

  @media (max-width: 480px) {
    width: 30%;
    left: -15%;
    bottom: -10%;
  }
`

const BackgroundBottomEnd = styled.img`
  position: absolute;
  right: -12%;
  bottom: 0;
  height: auto;
  object-fit: contain;
  transform: rotate(180deg);
  z-index: 2;

  @media (max-width: 998px) {
    right: -80%;
  }

  @media (max-width: 480px) {
    width: 50%;
    right: -35%;
  }
`

const SectionStoryStart: React.FC = () => (
  <Wrapper
    id={ESection.StoryStart}
    title={<Title color={EColor.Purple} line>Здравильность!</Title>}
    color={EColor.Purple}
    borders={[
      BORDER_TOP_LEFT,
      BORDER_TOP_RIGHT,
      BORDER_BOTTOM_LEFT,
      BORDER_BOTTOM_RIGHT
    ]}
  >
    <StoryStartDialogue />
    <StoryStartTeam />

    <BackgroundTopStart src={BACKGROUND_TOP_START} alt='Background Start' />
    <BackgroundTopEnd src={BACKGROUND_TOP_END} alt='Background End' />
    <BackgroundBottomStart src={BACKGROUND_BOTTOM_START} alt='Background Start' />
    <BackgroundBottomEnd src={BACKGROUND_BOTTOM_END} alt='Background End' />
  </Wrapper>
)

export default SectionStoryStart
