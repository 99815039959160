import styled from 'styled-components'
import React, { useMemo } from 'react'

import { EContactLinkType } from 'types'
import DefinitionCard from 'components/definition-card'
import Icon, { IconDefinition } from 'components/icon'

import { formatPhone } from './helpers'

interface Props {
  type: EContactLinkType
  value: string
}

const ICONS: Record<EContactLinkType, IconDefinition> = {
  [EContactLinkType.Phone]: 'PhoneBulk',
  [EContactLinkType.Email]: 'MessageBulk',
  [EContactLinkType.Address]: 'LocationBulk'
}

const Wrapper = styled(DefinitionCard)`
  font-size: 16px;
`

const ContactLink: React.FC<Props> = ({ type, value }) => {
  const href = useMemo(() => {
    if (type === EContactLinkType.Email) return `mailto:${value}`
    if (type === EContactLinkType.Phone) return `tel:${value}`
    return value
  }, [type, value])

  const formattedValue = useMemo(() => {
    if (type === EContactLinkType.Phone) return formatPhone(value)
    return value
  }, [type, value])

  return <Wrapper icon={<Icon type={ICONS[type]} />} href={href} value={formattedValue} />
}

export default ContactLink
