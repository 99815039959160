import { CSSObject } from 'styled-components'

export enum EFace {
  Man = 'man',
  Woman = 'woman',
  Team = 'team'
}

export enum ESection {
  Header = 'header',
  StoryStart = 'story-start',
  StoryEnd = 'story-end',
  Catalog = 'catalog',
  Partners = 'partners',
  Contacts = 'contacts'
}

export enum EColor {
  Purple = 'purple',
  Green = 'green',
  Skin = 'skin',
  Blue = 'blue',
  Gray = 'gray',
  Red = 'red'
}

export enum EContactLinkType {
  Email = 'email',
  Phone = 'phone',
  Address = 'address'
}

export type TTypographyRotation = {
  degree?: number
  direction?: 'inline-start' | 'inline-end'
}

export type TFile = {
  _id: string
  name: string
  path: string
  createdAt: number
  updatedAt: number
}

export type TProduct = {
  _id: string
  name: string
  body: string
  image: TFile
  startFile: TFile
  endFile: TFile
  description: string
  orderNumber: number
  isActive: boolean
  publishedAt: string
  createdAt: number
  updatedAt: number
}

export type TPartner = {
  _id: string
  url: string
  tooltip: string
  logotype: TFile
  createdAt: number
  updatedAt: number
}

export type TMessage = {
  _id: string
  text: string
  note?: string
  background?: {
    name?: string
    path?: string
    style?: CSSObject
  }
  face: EFace
}

export type TNavigationItem = {
  _id: string
  title: string
  icon: TFile
  color: string
  path: string
  createdAt: number
  updatedAt: number
}

export type TLocation = {
  lat: string
  lng: string
}

export type TSocial = TNavigationItem

export type TLabel = {
  _id: string
  label: string
}

export type TSettings = {
  _id: string
  aside: {
    title: string | null
    navigation: TNavigationItem[]
  }
  navigation: TNavigationItem[]
  contacts: {
    emails: TLabel[]
    phones: TLabel[]
    addresses: TLabel[]
    location: TLocation
    socials: TNavigationItem[]
    copyright: string
  }
  metadata: {
    title: string
    favicon: TFile
    logotype: TFile
    description: string
    keywords: string
  }
  createdAt: number
  updatedAt: number
}
