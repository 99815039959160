import React, { Fragment } from 'react'

import { IconDefinition } from './types'
import Icons from './icons'

interface Props {
  type: IconDefinition
}

const Icon: React.FC<Props> = ({ type }) => {
  const Component = Icons[type]

  return (
    <Fragment>
      <Component />
    </Fragment>
  )
}

export default Icon
