import { css } from 'styled-components';

export const ClearIndents = css`
  margin: 0;
  padding: 0;
`

export const Clear = {
  indents: ClearIndents,
  button: css`
    ${ClearIndents};
    border: none;
    box-shadow: none;
    background: none;
    font-size: inherit;
    font-family: inherit;
  `,
  ul: css`
    ${ClearIndents};

    li {
      ${ClearIndents};
    }
  `,
  a: css`
    ${ClearIndents};
    color: inherit;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active,
    &:visited {
      color: inherit;
    }
  `
}

export const FlexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StretchImage = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
