import styled from 'styled-components'
import React from 'react'

import { getCurrentYear } from 'helpers'
import { useSettingsContext } from 'context'
import { EColor, EContactLinkType, ESection } from 'types'
import Logotype from 'features/logotype'
import Section from 'components/section'
import Column from 'components/column'
import Socials from 'features/socials'
import Title from 'components/title'
import Text from 'components/text'
import Row from 'components/row'

import BORDER_TOP_LEFT from 'assets/images/border-gray-top-left.png'
import BORDER_TOP_RIGHT from 'assets/images/border-gray-top-right.png'
import BORDER_BOTTOM_LEFT from 'assets/images/border-gray-bottom-left.png'
import BORDER_BOTTOM_RIGHT from 'assets/images/border-gray-bottom-right.png'
import BACKGROUND_START from 'assets/images/food/food_19.png'
import BACKGROUND_END from 'assets/images/food/food_18.png'

import ContactLink from './contact-link'
import ContactMap from './contact-map'

const Wrapper = styled(Section)`
  justify-content: center;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 700px;
  margin: 50px auto;
  gap: 20px;

  @media (max-width: 998px) {
    width: 50%;
    margin: 0 auto;
  }

  @media (max-width: 480px) {
    width: auto;
  }
`

const StyledLogotype = styled(Logotype)`
  width: 260px;

  @media (max-width: 998px) {
    width: 100%;
  }
`

const ContactLinks = styled(Row)`
  flex-wrap: wrap;

  @media (max-width: 998px) {
    flex-direction: column;
  }
`

const Copyright = styled(Text)``

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  gap: 15px;

  & > div {
    justify-content: space-between;
  }

  @media (max-width: 998px) {
    flex-direction: column;
    align-items: center;
  }
`

const BackgroundStart = styled.img`
  position: absolute;
  left: -40%;
  bottom: 0;
  object-fit: contain;
  z-index: 2;

  @media (max-width: 480px) {
    height: 25%;
  }
`

const BackgroundEnd = styled.img`
  position: absolute;
  right: -40%;
  bottom: 0;
  object-fit: contain;
  z-index: 3;

  @media (max-width: 480px) {
    height: 35%;
  }
`

const SectionContacts: React.FC = () => {
  const [settings] = useSettingsContext()

  return (
    <Wrapper
      id={ESection.Contacts}
      title={<Title color={EColor.Gray} line>Контакты</Title>}
      color={EColor.Gray}
      borders={[
        BORDER_TOP_LEFT,
        BORDER_TOP_RIGHT,
        BORDER_BOTTOM_LEFT,
        BORDER_BOTTOM_RIGHT
      ]}
    >
      <Container>
        {(settings.contacts?.emails || settings.contacts?.phones || settings.contacts?.addresses) && (
          <ContactLinks gap={20}>
            {settings.contacts?.emails && (
              settings.contacts?.emails?.map(({ _id, label }) => (
                <ContactLink key={_id} type={EContactLinkType.Email} value={label} />
              ))
            )}
            {settings.contacts?.phones && (
              settings.contacts?.phones?.map(({ _id, label }) => (
                <ContactLink key={_id} type={EContactLinkType.Phone} value={label} />
              ))
            )}
            {settings.contacts?.addresses && (
              settings.contacts?.addresses?.map(({ _id, label }) => (
                <ContactLink key={_id} type={EContactLinkType.Address} value={label} />
              ))
            )}
          </ContactLinks>
        )}

        <ContactMap />

        <Footer>
          <StyledLogotype />
          <Column>
            <Socials />
            <Copyright>{settings.contacts?.copyright} &copy; {getCurrentYear()}</Copyright>
          </Column>
        </Footer>
      </Container>

      <BackgroundStart src={BACKGROUND_START} alt='Background Start' />
      <BackgroundEnd src={BACKGROUND_END} alt='Background End' />
    </Wrapper>
  )
}

export default SectionContacts
